// extracted by mini-css-extract-plugin
export var banner = "brand-module--banner--a7NHk";
export var bannerC = "brand-module--bannerC--0LDh1";
export var bannerImage = "brand-module--bannerImage--TcnwW";
export var bgLineLeft = "brand-module--bgLineLeft--ufD7L";
export var bgcircle = "brand-module--bgcircle--sbzyO";
export var ficonImage = "brand-module--ficonImage--ZMKf6";
export var layertext = "brand-module--layertext--hfhjj";
export var mb = "brand-module--mb--Zbc71";
export var mt = "brand-module--mt--B4w+c";
export var testimonial = "brand-module--testimonial--iq+6A";
export var tp = "brand-module--tp--Tz951";
export var verticalCarouselImage = "brand-module--verticalCarouselImage--tcruc";
export var verticalCarouselImageContainer = "brand-module--verticalCarouselImageContainer--3+ffJ";
export var verticalCarouselRow = "brand-module--verticalCarouselRow--NZsrU";